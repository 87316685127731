import { createArrayFromObject } from "../Types/arrayUtilities";
import { createLocation } from "./locationUtilities";

export const adminRoot = "/admin";
export const courseRoot = "/course";
export const trainingRoot = "/training";

export const params = {
    courseId: ":courseId",
    enrollmentId:":enrollmentId",
    categoryId: ":categoryId",
    districtName: ":districtName",
    moduleId: ":moduleId",
    moduleContentId: ":moduleContentId",
    updatePasswordGuid: ":updatePasswordGuid",
    surveyType: ":surveyType",
    surveyId: ":surveyId",
    userId: ":userId",
    startDate: ":startDate",
    endDate: ":endDate",
    reportType: ":reportType",
    siteGroupId: ":siteGroupId",
    notificationId: ":notificationId",
    regionId: ":regionId",
    creditType: ":creditType",
    pageId: ":pageId",
    selectedRole: ":selectedRole"
};

export const locationBehaviors = {
    requiresAuthentication: "requiresAuthentication",
    redirectToDashboard: "redirectToDashboardIfAuthenticated",
    doNotRedirectBack: "doNotRedirectBack",
    requiresAdminAuthentication: "requiresAdminAuthentication",
};

export const locations = {
    // GENERAL LOCATIONS
    COURSE_PREVIEW: createLocation((courseId = params.courseId) => `/catalog/course/${courseId}`, `Course Preview`, []),
    CATALOG: createLocation(() => `/catalog`, `Course Catalog`, []),
    CATEGORY: createLocation((categoryId = params.categoryId) => `/category/${categoryId}`, `Category`, []),
    DASHBOARD: createLocation(() => `/dashboard`, `My Dashboard`, [locationBehaviors.requiresAuthentication]),
    FORGOT_PASSWORD: createLocation(() => `/forgot-password`, `Forgot Password`, [locationBehaviors.redirectToDashboard]),
    SET_PASSWORD: createLocation(() => `/set-password`, `Set Password`, [locationBehaviors.redirectToDashboard]),
    LANDING: createLocation(() => `/home`, `Home`, []),
    COURSE_PROFESSIONAL_COMMUNITIES: createLocation(() => `/communities`, `Professional Communities`),
    COURSE_PROFESSIONAL_COMMUNITIES_CONTENT: createLocation((courseId = params.courseId, moduleId = params.moduleId, pageId = params.pageId) => `/communities/${courseId}/module/${moduleId}/page/${pageId}`, `View Resources`),
    COURSE_PROFESSIONAL_COMMUNITIES_ENROLL: createLocation((courseId = params.courseId) => `/communities/${courseId}/enroll`, `Enroll`, [locationBehaviors.requiresAuthentication]),
    COURSE_PROFESSIONAL_COMMUNITIES_RESOURCES: createLocation((courseId = params.courseId) => `/communities/${courseId}`, `View Resources`),
    PUBLIC_SURVEY: createLocation((notificationId = params.notificationId) => `/public/surveys/${notificationId}`, `Take survey`, []),
    UPDATE_PASSWORD: createLocation((updatePasswordGuid = params.updatePasswordGuid) => `/update-password/${updatePasswordGuid}`, `Update password`, [locationBehaviors.redirectToDashboard]),
    SIGN_IN: createLocation(() => `/sign-in`, `Sign in`, [locationBehaviors.redirectToDashboard]),
    SIGN_OUT: createLocation(() => `/goodbye`, `Sign out`, [locationBehaviors.requiresAuthentication, locationBehaviors.doNotRedirectBack]),
    USER_PROFILE: createLocation(() => `/user`, `Update User Profile`, [locationBehaviors.requiresAuthentication]),
    VALIDATE_COURSE_EVENT_CODES: createLocation(() => `/event`, `Event Certificate Request`, [locationBehaviors.requiresAuthentication]),
    FORWARD_TO_EVENT: createLocation(() => `/webinar`, `Event Certificate Request`, [locationBehaviors.requiresAuthentication]),
    DISCUSSION_BOARD: createLocation(() => `/discussion-board`, `Discussion Board Login Validation`, [locationBehaviors.requiresAuthentication]),

    // COURSE LOCATIONS
    ASSESSMENT: createLocation((courseId = params.courseId, surveyType = params.surveyType) => `${courseRoot}/${courseId}/assessment/${surveyType}`, `Take assessment`, [locationBehaviors.requiresAuthentication]),
    CERTIFICATE: createLocation((courseId = params.courseId, enrollmentId = params.enrollmentId) => `${courseRoot}/${courseId}/enrollments/${enrollmentId}/certificate`, `Certificate`, [locationBehaviors.requiresAuthentication]),
    COURSE: createLocation((courseId = params.courseId) => `${courseRoot}/${courseId}`, `View course`, [locationBehaviors.requiresAuthentication]),
    COURSE_ARCHIVE: createLocation((courseId = params.courseId) => `${courseRoot}/${courseId}/archived`, `View Course`),
    ENROLL: createLocation((courseId = params.courseId) => `${courseRoot}/enroll/${courseId}`, `Enroll`, [locationBehaviors.requiresAuthentication]),
    FOLLOWUP: createLocation((courseId = params.courseId) => `${courseRoot}/follow-up/${courseId}`, `Follow-Up Activity`, [locationBehaviors.requiresAuthentication]),
    MODULE: createLocation((courseId = params.courseId, moduleId = params.moduleId, pageId = params.pageId) => `${courseRoot}/${courseId}/module/${moduleId}/page/${pageId}`, `View module`, [locationBehaviors.requiresAuthentication]),
    REGISTER: createLocation(() => `${courseRoot}/register`, `Register`, [locationBehaviors.redirectToDashboard]),
    SURVEY: createLocation((courseId = params.courseId, surveyType = params.surveyType) => `${courseRoot}/${courseId}/survey/${surveyType}`, `Take survey`, [locationBehaviors.requiresAuthentication]),

    // TRAINING LOCATIONS
    TRAINING_VALIDATE: createLocation(() => `${trainingRoot}`, `Training Request`, [locationBehaviors.requiresAuthentication]),
    TRAINING_REGISTER: createLocation(() => `${trainingRoot}/register`, `Register`, [locationBehaviors.redirectToDashboard]),
    COURSE_TRAINING_QR_CODE: createLocation(() => `${adminRoot}/trainings/qrcode`, `Training QR Code`, [locationBehaviors.requiresAdminAuthentication]),

    // ADMIN LOCATIONS

    // Follow up
	FOLLOW_UP_APPROVAL: createLocation(() => `${adminRoot}/follow-up/approval`, `Follow-Up Activity Approval`, [locationBehaviors.requiresAdminAuthentication]),

    // Users
    ADD_ENROLLMENT: createLocation((userId = params.userId) => `${adminRoot}/users/${userId}/new`, `Add Enrollment`, [locationBehaviors.requiresAdminAuthentication]),
    ADD_USER: createLocation(() => `${adminRoot}/users/new`, `Add`, [locationBehaviors.requiresAdminAuthentication]),
    MERGE_USERS: createLocation(() => `${adminRoot}/users/merge`, `Merge`, [locationBehaviors.requiresAdminAuthentication]),
    IMPORT_USERS: createLocation(() => `${adminRoot}/users/import`, `Import Users`, [locationBehaviors.requiresAdminAuthentication]),
    IMPORT_TRACKING_STUDENTS: createLocation(() => `${adminRoot}/users/importStudents`, `Import Students`, [locationBehaviors.requiresAdminAuthentication]),
    CERTIFICATE_ADMIN: createLocation((courseId = params.courseId, userId = params.userId, enrollmentId = params.enrollmentId) => `${adminRoot}/users/${userId}/course/${courseId}/certificate/enrollments/${enrollmentId}`, `Certificate`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_ENROLLMENT: createLocation((courseId = params.courseId, userId = params.userId, enrollmentId = params.enrollmentId) => `${adminRoot}/users/${userId}/course/${courseId}/enrollments/${enrollmentId}`, `Edit Enrollment`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_USER: createLocation((userId = params.userId) => `${adminRoot}/users/${userId}`, `Edit User`, [locationBehaviors.requiresAdminAuthentication]),
    ENROLLMENTS: createLocation((userId = params.userId) => `${adminRoot}/users/${userId}/enrollments`, `Enrollments`, [locationBehaviors.requiresAdminAuthentication]),
    USER_SEARCH: createLocation(() => `${adminRoot}/users/search`, `Manage Users`, [locationBehaviors.requiresAdminAuthentication]),

    // Reports
	HTML_REPORTS: createLocation((
	    reportType = params.reportType,
        siteGroupId = params.siteGroupId,
        startDate = params.startDate,
        endDate = params.endDate,
        courseId = params.courseId,
        regionId = params.regionId,
        districtName = params.districtName,
        creditType = params.creditType,
        categoryId = params.categoryId,
        selectedRole = params.selectedRole) =>
        `${adminRoot}/reports/${reportType}/siteGroups/${siteGroupId}/startDate/${startDate}/endDate/${endDate}/courses/${courseId}/regions/${regionId}/creditType/${creditType}/districts/${districtName}/categories/${categoryId}/roles/${selectedRole}`, `Print Report`, [locationBehaviors.requiresAdminAuthentication]),
    REPORTS: createLocation(() => `${adminRoot}/reports`, `Access Reports`, [locationBehaviors.requiresAdminAuthentication]),

    // Surveys
	ADD_SURVEY: createLocation(() => `${adminRoot}/surveys/new`, `Add Evaluation`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_CYK_SURVEY_CONTENT: createLocation((surveyId = params.surveyId, moduleId = params.moduleId, courseId = params.courseId) => `${adminRoot}/courses/${courseId}/modules/${moduleId}/surveys/${surveyId}/content/`, `Edit Evaluation Content`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_SURVEY: createLocation((surveyId = params.surveyId) => `${adminRoot}/surveys/${surveyId}`, `Edit Evaluation`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_SURVEY_CONTENT: createLocation((surveyId = params.surveyId) => `${adminRoot}/surveys/${surveyId}/content/`, `Edit Evaluation Content`, [locationBehaviors.requiresAdminAuthentication]),
    ORDER_CYK_SURVEY: createLocation((surveyId = params.surveyId, moduleId = params.moduleId, courseId = params.courseId) => `${adminRoot}/courses/${courseId}/modules/${moduleId}/surveys/${surveyId}/order`, `Order Evaluations`, [locationBehaviors.requiresAdminAuthentication]),
    ORDER_SURVEY: createLocation((surveyId = params.surveyId) => `${adminRoot}/surveys/${surveyId}/order`, `Order Evaluations`, [locationBehaviors.requiresAdminAuthentication]),
    SURVEY_SEARCH: createLocation(() => `${adminRoot}/surveys/search`, `Manage Evaluations`, [locationBehaviors.requiresAdminAuthentication]),

    // Categories
    ADD_CATEGORY: createLocation(() => `${adminRoot}/categories/new`, `Add Category`, [locationBehaviors.requiresAdminAuthentication]),
    CATEGORY_SEARCH: createLocation(() => `${adminRoot}/categories/search`, `Manage Categories`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_CATEGORY: createLocation((categoryId = params.categoryId) => `${adminRoot}/categories/${categoryId}`, `Edit Category`, [locationBehaviors.requiresAdminAuthentication]),
    ORDER_CATEGORY: createLocation((categoryId = params.categoryId) => `${adminRoot}/categories/${categoryId}/order`, `Order Categories`, [locationBehaviors.requiresAdminAuthentication]),

    // Courses
    ADD_COURSE: createLocation(() => `${adminRoot}/courses/new`, `Add Course`, [locationBehaviors.requiresAdminAuthentication]),
    COURSE_SEARCH: createLocation(() => `${adminRoot}/courses`, `Manage Courses`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_COURSE: createLocation((courseId = params.courseId) => `${adminRoot}/courses/${courseId}`, `Edit Course`, [locationBehaviors.requiresAdminAuthentication]),

    // Course Events
    ADD_COURSE_EVENT: createLocation(() => `${adminRoot}/events/new`, `Add Event`, [locationBehaviors.requiresAdminAuthentication]),
    COURSE_EVENT_ENROLLMENTS: createLocation((courseId = params.courseId) => `${adminRoot}/events/${courseId}/enrollments`, `Registered Users`, [locationBehaviors.requiresAdminAuthentication]),
    COURSE_EVENT_SEARCH: createLocation(() => `${adminRoot}/events`, `Manage Events`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_COURSE_EVENT: createLocation((courseId = params.courseId) => `${adminRoot}/events/${courseId}`, `Edit Event`, [locationBehaviors.requiresAdminAuthentication]),

    //Training
    COURSE_TRAINING_ENROLLMENT_DETAILS: createLocation((courseId = params.courseId, enrollmentId = params.enrollmentId) => `${adminRoot}/trainings/${courseId}/enrollments/${enrollmentId}`, `Registered Users`, [locationBehaviors.requiresAdminAuthentication]),
    ADD_COURSE_TRAINING: createLocation(() => `${adminRoot}/trainings/new`, `Add Training`, [locationBehaviors.requiresAdminAuthentication]),
    COURSE_TRAINING_ENROLLMENTS: createLocation((courseId = params.courseId) => `${adminRoot}/trainings/${courseId}/enrollments`, `Registered Users`, [locationBehaviors.requiresAdminAuthentication]),
    COURSE_TRAINING_SEARCH: createLocation(() => `${adminRoot}/trainings`, `Manage Trainings`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_COURSE_TRAINING: createLocation((courseId = params.courseId) => `${adminRoot}/trainings/${courseId}`, `Edit Training`, [locationBehaviors.requiresAdminAuthentication]),


    // Professional Communities
    ADD_COURSE_PROFESSIONAL_COMMUNITIES: createLocation(() => `${adminRoot}/communities/new`, `Add Professional Community`, [locationBehaviors.requiresAdminAuthentication]),
    ADD_PROFESSIONAL_COMMUNITIES_CONTENT: createLocation((courseId = params.courseId) => `${adminRoot}/communities/${courseId}/contents/new`, `Add Professional Community Content`, [locationBehaviors.requiresAdminAuthentication]),
    COURSE_PROFESSIONAL_COMMUNITIES_ENROLLMENTS: createLocation((courseId = params.courseId) => `${adminRoot}/communities/${courseId}/enrollments`, `Registered Users`, [locationBehaviors.requiresAdminAuthentication]),
    COURSE_PROFESSIONAL_COMMUNITIES_SEARCH: createLocation(() => `${adminRoot}/communities`, `Manage Professional Communities`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_COURSE_PROFESSIONAL_COMMUNITIES: createLocation((courseId = params.courseId) => `${adminRoot}/communities/${courseId}`, `Edit Professional Community`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_PROFESSIONAL_COMMUNITIES_CONTENT: createLocation((courseId = params.courseId, moduleContentId = params.moduleContentId) => `${adminRoot}/communities/${courseId}/contents/${moduleContentId}`, `Edit Professional Community Content`, [locationBehaviors.requiresAdminAuthentication]),
    ORDER_PROFESSIONAL_COMMUNITIES_CONTENT: createLocation((courseId = params.courseId) => `${adminRoot}/communities/${courseId}/order`, `Order Professional Community Content`, [locationBehaviors.requiresAdminAuthentication]),
    PROFESSIONAL_COMMUNITIES_CONTENT_LIST: createLocation((courseId = params.courseId) => `${adminRoot}/communities/${courseId}/contents`, `Manage Professional Community Content`, [locationBehaviors.requiresAdminAuthentication]),

    // Modules
    ADD_MODULE: createLocation((courseId = params.courseId) => `${adminRoot}/courses/${courseId}/modules/new`, `Add Module`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_MODULE: createLocation((courseId = params.courseId, moduleId = params.moduleId) => `${adminRoot}/courses/${courseId}/modules/${moduleId}`, `Edit Module`, [locationBehaviors.requiresAdminAuthentication]),
    MODULE_LIST: createLocation((courseId = params.courseId) => `${adminRoot}/courses/${courseId}/modules`, `Manage Modules`, [locationBehaviors.requiresAdminAuthentication]),
    ORDER_MODULES: createLocation((courseId = params.courseId) => `${adminRoot}/courses/${courseId}/order`, `Order Modules`, [locationBehaviors.requiresAdminAuthentication]),

    // Modules Content Pages
    ADD_MODULE_PAGE: createLocation((courseId = params.courseId, moduleId = params.moduleId) => `${adminRoot}/courses/${courseId}/modules/${moduleId}/contents/new`, `Add Module Pages`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_MODULE_PAGE: createLocation((courseId = params.courseId, moduleId = params.moduleId, moduleContentId = params.moduleContentId) => `${adminRoot}/courses/${courseId}/modules/${moduleId}/contents/${moduleContentId}`, `Edit Module Pages`, [locationBehaviors.requiresAdminAuthentication]),
    MODULE_PAGE_LIST: createLocation((courseId = params.courseId, moduleId = params.moduleId) => `${adminRoot}/courses/${courseId}/modules/${moduleId}/contents`, `Manage Module Pages`, [locationBehaviors.requiresAdminAuthentication]),
    ORDER_MODULE_PAGE: createLocation((courseId = params.courseId, moduleId = params.moduleId) => `${adminRoot}/courses/${courseId}/modules/${moduleId}/order`, `Order Module Pages`, [locationBehaviors.requiresAdminAuthentication]),

    // Modules Content Sections
    ADD_MODULE_SECTION: createLocation((courseId = params.courseId, moduleId = params.moduleId, pageId = params.pageId) => `${adminRoot}/courses/${courseId}/modules/${moduleId}/page/${pageId}/contents/new`, `Add Module Sections`, [locationBehaviors.requiresAdminAuthentication]),
    EDIT_MODULE_SECTION: createLocation((courseId = params.courseId, moduleId = params.moduleId, pageId = params.pageId, moduleContentId = params.moduleContentId) => `${adminRoot}/courses/${courseId}/modules/${moduleId}/page/${pageId}/contents/${moduleContentId}`, `Edit Module Sections`, [locationBehaviors.requiresAdminAuthentication]),
    MODULE_SECTION_LIST: createLocation((courseId = params.courseId, moduleId = params.moduleId, pageId = params.pageId) => `${adminRoot}/courses/${courseId}/modules/${moduleId}/page/${pageId}/contents`, `Manage Module Sections`, [locationBehaviors.requiresAdminAuthentication]),
    ORDER_MODULE_SECTION: createLocation((courseId = params.courseId, moduleId = params.moduleId, pageId = params.pageId) => `${adminRoot}/courses/${courseId}/modules/${moduleId}/page/${pageId}/order`, `Order Module Sections`, [locationBehaviors.requiresAdminAuthentication]),

	// Sad paths
    ENROLLMENT_NOT_ALLOWED: createLocation(() => `/enrollment/notAllowed`, `Enrollment Not Allowed`, [locationBehaviors.doNotRedirectBack]),
    NOT_AUTHORIZED: createLocation(() => `/notAuthorized`, `Not Authorized`, [locationBehaviors.requiresAuthentication, locationBehaviors.doNotRedirectBack]),
	NOT_FOUND: createLocation(() => `/pageNotFound`, `Not Found`, [locationBehaviors.doNotRedirectBack]),
};

export const allLocations = createArrayFromObject(locations);

