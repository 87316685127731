import React from "react";
import PropTypes from "prop-types";

const ByLocationRow = ({
                           enrollmentsByLocationReportDtos
                       }) => {

    if (!enrollmentsByLocationReportDtos)
        return null;

    let distinctTotal = 0;
    let enrollmentsTotal = 0;
    let completersTotal = 0;

    return (
        <>
            <tbody>
            {
                enrollmentsByLocationReportDtos.map(({location, distinctEnrollments, totalEnrollments, numberOfCourseCompleters}, rIndex) => {
                    distinctTotal += distinctEnrollments;
                    enrollmentsTotal += totalEnrollments;
                    completersTotal += numberOfCourseCompleters;

                    return (
                        <tr key={`enrollmentsByLocation_${rIndex}`}>
                            <td>{location || `<blank>`}</td>
                            <td className="text-center">{distinctEnrollments}</td>
                            <td className="text-center">{totalEnrollments}</td>
                            <td className="text-center">{numberOfCourseCompleters}</td>
                        </tr>
                    )
                })
            }
            </tbody>
            <tfoot>
            <tr>
                <td>All</td>
                <td className="text-center">{distinctTotal}</td>
                <td className="text-center">{enrollmentsTotal}</td>
                <td className="text-center">{completersTotal}</td>
            </tr>
            </tfoot>
        </>
    );
};

ByLocationRow.propTypes = {
    enrollmentsByLocationReportDtos: PropTypes.array,
};

export default React.memo(ByLocationRow);
