import React from "react";
import PropTypes from "prop-types";
import GridTable from "../../../../Components/Grid/GridTable";
import CourseEventRow from "./CourseEventRow";

const ByCourseTable = ({
                            enrollmentsByCourseReportDtos
                        }) => {
    if (!enrollmentsByCourseReportDtos)
        return null;

    return (
        <>
            <h2>Enrollments By Course</h2>
            <GridTable>
                <thead>
                <tr>
                    <th scope={"col"} className={"align-center"}>
                        Course Name
                    </th>
                    <th scope={"col"} className={"align-center"}>
                        Course Enrollments
                    </th>
                    <th scope={"col"} className={"align-center"}>
                        Course Completers
                    </th>
                </tr>
                </thead>
                <CourseEventRow enrollmentsByCourseReportDtos={enrollmentsByCourseReportDtos} isCourseEvent={false}/>
            </GridTable>
        </>
    );
};

ByCourseTable.propTypes = {
    enrollmentsByCourseReportDtos: PropTypes.array
};

export default React.memo(ByCourseTable);
