import React from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../../Components/Landmarks";
import ByLocationTable from "./Enrollments/ByLocationTable";
import ByCourseTable from "./Enrollments/ByCourseTable";
import ByProfessionalCommunityTable from "./Enrollments/ByProfessionalCommunityTable";
import ByCourseEventTable from "./Enrollments/ByCourseEventTable";

const SiteSummaryPrintView = ({
                                  htmlReportInfo,
                                  siteDetails
                              }) => {

    if(!htmlReportInfo)
        return null;

    const hasResults = htmlReportInfo.totalNumberOfParticipants > 0
        || htmlReportInfo.enrollmentsByCourseReportDtos.length > 0
        || htmlReportInfo.enrollmentsByLocationReportDtos.length > 0
        || htmlReportInfo.courseEventEnrollmentsByCourseReportDtos.length > 0
        || htmlReportInfo.professionalCommunityEnrollmentsByCourseReportDtos.length > 0;

    return(
        <div className={"print-container"}>
            <PageTitle h1={"Site Summary Report"} isCentered hasBorder />
            {
                !hasResults &&
                <p className={"no-report"}>No results found.</p>
            }
            {
                hasResults &&
                <>
                    <div className={"report-summary"}>
                        <p><b>Total Number of Users Registered: </b>{htmlReportInfo.totalNumberOfParticipants}</p>
                        <hr/>
                        <ByCourseTable enrollmentsByCourseReportDtos={htmlReportInfo.enrollmentsByCourseReportDtos} />
                        <ByLocationTable enrollmentsByLocationReportDtos={htmlReportInfo.enrollmentsByLocationReportDtos} />
                        {
                            siteDetails.hasCourseEvent &&
                            <ByCourseEventTable enrollmentsByCourseReportDtos={htmlReportInfo.courseEventEnrollmentsByCourseReportDtos} />
                        }
                        {
                            siteDetails.hasProfessionalCommunities &&
                            <ByProfessionalCommunityTable professionalCommunityEnrollmentsByCourseReportDtos={htmlReportInfo.professionalCommunityEnrollmentsByCourseReportDtos} />
                        }
                    </div>
                </>
            }
        </div>
    );
};

SiteSummaryPrintView.propTypes = {
    htmlReportInfo: PropTypes.object,
    siteDetails: PropTypes.object.isRequired
};

export default SiteSummaryPrintView;
