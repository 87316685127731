import React from "react";
import PropTypes from "prop-types";
import CertificateView from "../../../Components/Certificate/CertificateView";
import {PageTitle} from "../../../Components/Landmarks";
import PageNavigation from "../../../Components/Landmarks/PageNavigation";
import {ContentMedium} from "../../../Components/Layout";
import {enrollmentModel, userModel} from "../../User/userFactory";

export const BulkCertificatesDownloadPrintView = ({
                                  htmlReportInfo
                              }) => {

    if(!htmlReportInfo)
        return null;

    const hasResults = htmlReportInfo.length > 0;
    const handleSecondaryClick = () => window.close();
    const labelSecondary = "Close Window";

    return <>
        {
            !hasResults &&
            <div className={`print-container`}>
                <PageTitle h1={"Bulk Certificates Download"} isCentered hasBorder/>
                <p className={"no-report"}>No results found.</p>
                <ContentMedium>
                    <PageNavigation labelPrimary={labelSecondary} handlePrimaryClick={handleSecondaryClick} />
                </ContentMedium>
            </div>
        }
        {
            hasResults &&
            htmlReportInfo.map((report, index) => {
                const user = userModel(report.user);
                const enrollment = enrollmentModel(report.enrollment);
                return <CertificateView
                    key={index}
                    course={report.course}
                    enrollment={enrollment}
                    handleSecondaryClick={handleSecondaryClick}
                    labelSecondary={labelSecondary}
                    user={user}
                />;
            })
        }
    </>;
};

BulkCertificatesDownloadPrintView.propTypes = {
    htmlReportInfo: PropTypes.array
};