import React from "react";
import PropTypes from "prop-types";
import GridTable from "../../../../Components/Grid/GridTable";
import CourseEventRow from "./CourseEventRow";

const ByCourseEventTable = ({
                           enrollmentsByCourseReportDtos
                       }) => {
    if (!enrollmentsByCourseReportDtos)
        return null;

    return (
        <>
            <h2>Enrollments By Event</h2>
            <GridTable>
                <thead>
                <tr>
                    <th scope={"col"} className={"align-center"}>
                        Event Name
                    </th>
                    <th scope={"col"} className={"align-center"}>
                        Number Of Event Completers
                    </th>
                </tr>
                </thead>
                <CourseEventRow enrollmentsByCourseReportDtos={enrollmentsByCourseReportDtos} isCourseEvent={true}/>
            </GridTable>
        </>
    );
};


ByCourseEventTable.propTypes = {
    enrollmentsByCourseReportDtos: PropTypes.array
};

export default React.memo(ByCourseEventTable);
