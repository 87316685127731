import React from "react";
import GridTable from "../../../../Components/Grid/GridTable";
import PropTypes from "prop-types";
import ByLocationRow from "./ByLocationRow";

const ByLocationTable = ({
                             enrollmentsByLocationReportDtos
                         }) => {

    if (!enrollmentsByLocationReportDtos)
        return null;

    return (
        <>
            <h2>Enrollments By Location</h2>
            <GridTable>
                <thead>
                <tr>
                    <th scope={"col"} className={"align-center"}>
                        Location
                    </th>
                    <th scope={"col"} className={"align-center"}>
                        Account Enrollments
                    </th>
                    <th scope={"col"} className={"align-center"}>
                        Course Enrollments
                    </th>
                    <th scope={"col"} className={"align-center"}>
                        Course Completers
                    </th>
                </tr>
                </thead>
                <ByLocationRow enrollmentsByLocationReportDtos={enrollmentsByLocationReportDtos}/>
            </GridTable>
        </>
    );
};

ByLocationTable.propTypes = {
    enrollmentsByLocationReportDtos: PropTypes.array,
};

export default React.memo(ByLocationTable);
