import PropTypes from "prop-types";
import React from "react";
import { ContentMedium } from "../../Components/Layout";
import { courseTypes } from "../../Containers/Course/courseConstants";
import { HtmlDisplay } from "../Display";
import { useDocumentTitle } from "../Landmarks/landmarksUtility";
import PageNavigation from "../Landmarks/PageNavigation";
import { convertDateToPrintString } from "../../Utilities/Types/dateUtilities";

const CertificateView = ({
    enrollment,
    course,
    user,
    handleSecondaryClick,
    labelSecondary,
}) => {

    const handlePrimaryClick = (event) => {
        event.preventDefault();
        window.print();
    };

    const { firstName, lastName } = user;
    const { dateStarted, dateFinished, enrollmentId, creditEarned } = enrollment;
    const { courseTitleForCertificate, credits, trainingTitle } = course;
    const pageTitle = "Certificate of Completion";
    const printDate = convertDateToPrintString(dateFinished);
    const presentedDate = convertDateToPrintString(dateStarted);


    let certificateHours = !creditEarned ? credits : creditEarned;
    certificateHours = certificateHours.replace(/p>/g, "span>");
    const certificateClass = course.courseType === courseTypes.EventCourse.id ? "certificate--event" : "";
    const isTraining = course.courseType === courseTypes.Training.id;

    useDocumentTitle(pageTitle);

    return (
        <div className={`certificate-container`}>
            <h1 className="show-for-sr">{pageTitle}</h1>

            <div className={`certificate ${certificateClass}`}>
                <div className="certificate__background"/>

                <div className="certificate__presentedTo show-for-sr">Presented To</div>

                <div className="certificate__name">
                    {`${firstName} ${lastName}`}
                </div>

                <div className={`certificate__title`}>
                    <span className="show-for-sr">For completion of</span>
                    {isTraining ? trainingTitle : courseTitleForCertificate}
                </div>

                <div className={`certificate__date`}>
                    <span className="show-for-sr">on</span>
                    {printDate}
                </div>

                <div className={`certificate__presented`}>
                    <span className="show-for-sr">presented on</span>
                    {presentedDate}
                </div>

                {!isTraining &&
                    <>
                        <span className="show-for-sr">awarded</span>
                        <HtmlDisplay className={`certificate__hours`} html={certificateHours}
                        />
                    </>
                }

                <div className="certificate__footer"/>

                <div className={`certificate__identifier`} aria-hidden="true">
                    {enrollmentId}
                </div>

            </div>
            <ContentMedium>
                <PageNavigation labelPrimary={`Print Certificate`} labelSecondary={labelSecondary}
                    handleSecondaryClick={handleSecondaryClick} handlePrimaryClick={handlePrimaryClick} />
            </ContentMedium>
        </div>
    );
};

CertificateView.propTypes = {
    enrollment: PropTypes.object.isRequired,
    course: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    handleSecondaryClick: PropTypes.func.isRequired,
    labelSecondary: PropTypes.string.isRequired,
};

export default CertificateView;
