import React from "react";
import PropTypes from "prop-types";

const CourseEventRow = ({
                           enrollmentsByCourseReportDtos,
                           isCourseEvent
                       }) => {

    if (!enrollmentsByCourseReportDtos)
        return null;

    let enrollmentsTotal = 0;
    let completersTotal = 0;

    return(
        <>
            <tbody>
            {
                enrollmentsByCourseReportDtos.map(({courseTitle, eventTitle, totalEnrollments, numberOfCourseCompleters}, rIndex) => {
                    enrollmentsTotal += totalEnrollments;
                    completersTotal += numberOfCourseCompleters;

                    return (
                        <tr key={`enrollmentsByCourse_${rIndex}`}>
                            <td>{isCourseEvent ? eventTitle : courseTitle}</td>
                            {!isCourseEvent && <td className="text-center">{totalEnrollments}</td>}
                            <td className="text-center">{numberOfCourseCompleters}</td>
                        </tr>
                    )
                })
            }
            </tbody>
            <tfoot>
            <tr>
                <td>All</td>
                {!isCourseEvent && <td className="text-center">{enrollmentsTotal}</td>}
                <td className="text-center">{completersTotal}</td>
            </tr>
            </tfoot>
        </>
    );
};

CourseEventRow.propTypes = {
    enrollmentsByCourseReportDtos: PropTypes.array,
    isCourseEvent: PropTypes.bool
};

export default React.memo(CourseEventRow);
