import React from "react";
import PropTypes from "prop-types";
import GridTable from "../../../../Components/Grid/GridTable";

const ByProfessionalCommunityTable = ({
                                          professionalCommunityEnrollmentsByCourseReportDtos
                        }) => {
    if (!professionalCommunityEnrollmentsByCourseReportDtos)
        return null;

    let enrollmentsTotal = 0;

    return (
        <>
            <h2>Enrollments By Professional Communities</h2>
            <GridTable>
                <thead>
                <tr>
                    <th scope={"col"} className={"align-center"}>
                        Professional Community
                    </th>
                    <th scope={"col"} className={"align-center"}>
                        Total Enrollments
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    professionalCommunityEnrollmentsByCourseReportDtos.map(({professionalCommunityTitle, totalEnrollments}, rIndex) => {
                        enrollmentsTotal += totalEnrollments;

                        return (
                            <tr key={`enrollmentsByCommunity_${rIndex}`}>
                                <td>{professionalCommunityTitle}</td>
                                <td className="text-center">{totalEnrollments}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
                <tfoot>
                <tr>
                    <td>All</td>
                    <td className="text-center">{enrollmentsTotal}</td>
                </tr>
                </tfoot>
            </GridTable>
        </>
    );
};


ByProfessionalCommunityTable.propTypes = {
    professionalCommunityEnrollmentsByCourseReportDtos: PropTypes.array
};

export default React.memo(ByProfessionalCommunityTable);
